import React, { createContext, useState } from "react";
import { IntlProvider } from "react-intl";

import messages_en from "../../assets/data/locales/en.json";
import messages_th from "../../assets/data/locales/th.json";

export const Context = createContext({});

const TranslationStore = ({ children }) => {
  const menu_messages = {
    en: messages_en,
    th: messages_th,
  };
  const [lang, setLang] = useState("en");
  const store = {
    lang: { get: lang, set: setLang },
  };
  return (
    <Context.Provider value={store}>
      <IntlProvider
        key={lang}
        locale={lang || "en"}
        messages={menu_messages[lang]}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default TranslationStore;
