import { Dialog, IconButton } from "@mui/material";
import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import qr from "../images/wechat-qr.jpeg";

const DialogQrWeChat = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { p: 3, borderRadius: "8px" } }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          ml: "auto",
          background: "#e09f00 !important",
          color: "white",
          transition: "0.2s",

          "&:hover": { transform: "scale(1.2)" },
        }}
      >
        <CloseRoundedIcon />
      </IconButton>
      <img src={qr} alt="" style={{ maxWidth: "300px" }} />
    </Dialog>
  );
};

export default DialogQrWeChat;
