import { Box, Divider, Typography, useTheme } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
// import six from "../images/six.png";
// import ttt from "../images/ttt.png";
// import ydm from "../images/ydm.png";

const Title = () => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-end"
      flexWrap="wrap"
      mb={{ xs: 5, md: 7 }}
    >
      <div>
        <Divider
          width="100%"
          sx={{ background: theme.palette.primary.main, height: "9px", mb: 1 }}
        />
        <Typography variant="h3" textTransform="uppercase">
          <FormattedMessage id="Transform your fighter" />
        </Typography>
      </div>

      {/* <Box display="flex" alignItems="center" pt={{ xs: 1, md: 0 }}>
        <img
          src={ydm}
          alt=""
          className="flex-shrink"
          height={md ? 60 : 40}
          style={{ marginRight: "24px" }}
        />
        <img
          src={six}
          alt=""
          className="flex-shrink"
          height={md ? 60 : 40}
          style={{ marginRight: "24px" }}
        />
        <img src={ttt} alt="" className="flex-shrink" height={md ? 60 : 40} />
      </Box> */}
    </Box>
  );
};

export default Title;
