import { Box, styled, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import ButtonGradient from "../components/ButtonGradient";
import connect from "../images/connect.png";
import { UnsupportedChainIdError, useWeb3React } from "web3-react-core";
import { injected } from "../connectors";

const BorderBox = styled(Box)`
  border: 10px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  padding-top: 0;
`;

const ConnectWalletPage = () => {
  const navigate = useNavigate();
  const { active, account, activate, deactivate } = useWeb3React();

  useEffect(() => {
    if (active) {
      navigate("/");
    }
  }, [active]);

  const handleConnectWallet = () => {
    activate(injected, undefined, true)
      .then(async () => {
        // eslint-disable-next-line
        const walletAddress = await injected.getAccount();
        localStorage.setItem("isWalletConnected", "connected");
      })
      .catch((error) => {
        if (error instanceof UnsupportedChainIdError) {
          activate(injected);
        } else {
          console.error(error);
        }
      });
  };

  const logout = () => {
    localStorage.setItem("isWalletConnected", "disconnect");
    deactivate();
  };

  return (
    <>
      <BorderBox
        maxWidth="400px"
        mx="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={3}
      >
        <Box
          maxWidth={{ xs: "100%", sm: "initial" }}
          mb={{ xs: 2, sm: "-15%" }}
        >
          <img
            src={connect}
            alt=""
            className="d-block"
            style={{ maxWidth: "inherit" }}
          />
        </Box>

        <ButtonGradient
          fullWidth
          sx={{ mt: "auto" }}
          onClick={handleConnectWallet}
        >
          <FormattedMessage id="Connect Wallet" />
        </ButtonGradient>
      </BorderBox>

      <Box maxWidth="560px" mx="auto" mt={4}>
        <Typography
          align="center"
          variant="h5"
          fontWeight="normal"
          whiteSpace="pre-line"
          mb={2}
        >
          <FormattedMessage id="Welcome" />
        </Typography>

        <Typography align="center" fontWeight={300} whiteSpace="pre-line">
          <FormattedMessage id="The ability" />
          {`\n`}
          <FormattedMessage id="Connect" />
        </Typography>
      </Box>
    </>
  );
};

export default ConnectWalletPage;
