import { Box, Link } from "@mui/material";
import React, { useState } from "react";
import discord from "../images/socials/discord.png";
import line from "../images/socials/line.png";
// import looksrare from "../images/socials/looksrare.png";
// import opensea from "../images/socials/opensea.png";
// import rarible from "../images/socials/rarible.png";
import telegram from "../images/socials/telegram.png";
import twitter from "../images/socials/twitter.png";
import wechat from "../images/socials/wechat.png";
import DialogQrWeChat from "./DialogQrWeChat";

const Socials = ({ className, btnClassName, height, ...props }) => {
  const [showWeChat, setShowWeChat] = useState(false);

  const socials = [
    { img: discord, url: "https://discord.gg/ASqMhXS8u7" },
    { img: twitter, url: "https://twitter.com/Buakaw113" },
    { img: telegram, url: "https://t.me/buakaw1_channel" },
    { img: wechat, url: "#" },
    {
      img: line,
      url: "https://line.me/ti/g2/VRRaDIy7m1yIqrEG07DFKwuXTM0PU8HRQyqFdQ?utm_source=invitation&utm_medium=link_copy&utm_campaign=default",
    },
    // { img: rarible, url: "https://rarible.com/buakaw1" },
    // { img: opensea, url: "https://opensea.io/collection/buakaw1" },
    // {
    //   img: looksrare,
    //   url: "https://looksrare.org/collections/0x9F1CC70b11f4129d042d0037c2066d12E16d9a52",
    // },
  ];

  const onClose = () => {
    setShowWeChat(false);
  };

  return (
    <>
      <Box className={`d-flex ${className}`} {...props}>
        {socials.map((s, index) => (
          <Link
            href={s.url !== "#" ? s.url : undefined}
            onClick={
              s.url === "#"
                ? () => {
                    setShowWeChat(true);
                  }
                : undefined
            }
            target="_blank"
            rel="noreferrer"
            key={index}
            className={btnClassName}
            sx={{ px: { xs: 1, md: 2, cursor: "pointer" } }}
          >
            <img
              src={s.img}
              alt=""
              height={height || 22}
              width="auto"
              style={{ maxWidth: "initial" }}
            />
          </Link>
        ))}
      </Box>

      <DialogQrWeChat open={showWeChat} onClose={onClose} />
    </>
  );
};

export default Socials;
