import {
  Box,
  Card,
  LinearProgress,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import _ from "lodash";
import React from "react";
import RatioBox from "./RatioBox";

const CardStyle = styled(Card)`
  filter: drop-shadow(0px 0px 6px ${({ theme }) => theme.palette.primary.main});

  img {
    display: block;
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: fit-content;
    min-width: 90%;
  }
`;

const Preview = ({ nft, evolveType = "left", height }) => {
  const [londing, setLoading] = React.useState(true);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    setLoading(true);
  }, [evolveType]);

  return (
    <CardStyle>
      <RatioBox
        width={md ? `${height + 64}px !important` : undefined}
        className={londing ? "loading" : ""}
      >
        {londing && (
          <div>
            <LinearProgress />
          </div>
        )}
        <img
          src={
            !nft.canLeftClaim && !nft.canRightClaim
              ? nft.onchain_image
                ? nft.onchain_image
                : nft.origin_image
              : evolveType === "left"
              ? nft.linkLeft
              : nft.linkRight
          }
          onLoad={() => setLoading(false)}
        />
      </RatioBox>
      <Box sx={{ background: "#0D0D0D" }} p={2}>
        <Typography fontWeight={700} noWrap>
          {_.get(nft, "title", "UNKNOWN").split("#")[0]}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
          fontWeight={700}
          noWrap
        >
          #{_.get(nft, "tokenId", "0000").padStart(4, "0")}
        </Typography>
      </Box>
    </CardStyle>
  );
};

export default Preview;
