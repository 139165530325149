import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SixDataChainConnector } from "@sixnetwork/six-data-chain-sdk";
import { Alchemy, Network } from "alchemy-sdk";
import axios from "axios";
import { ethers } from "ethers";
import _ from "lodash";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useWeb3React } from "web3-react-core";
import AlertPopup from "../components/AlertPopup";
import ButtonGradient from "../components/ButtonGradient";
import LoadingPage from "../components/LoadingPage";
import MyNFT from "../components/MyNFT";
import Preview from "../components/Preview";
import Title from "../components/Title";
import left from "../images/left.png";
import right from "../images/right.png";
import abi from "../utils/abi.json";

const sixConnector = new SixDataChainConnector();
sixConnector.apiUrl = process.env.REACT_APP_CURRENT_ENV === "production" ? "https://sixnet-api.sixprotocol.net/" : "https://api1.fivenet.sixprotocol.net/";

const settings = {
  apiKey: process.env.REACT_APP_ALCHEMY_KEY,
  network: 
    process.env.REACT_APP_CURRENT_ENV === "production"
      ? Network.ETH_MAINNET
      : Network.ETH_GOERLI
};

const alchemy = new Alchemy(settings);

const TransformButton = ({ isLoading, ...props }) => {
  return (
    <ButtonGradient fullWidth sx={{ my: 2 }} {...props}>
      {isLoading && (
        <CircularProgress size={16} className="d-block mr-3" color="inherit" />
      )}

      {isLoading ? <FormattedMessage id="Loading" /> : "TRANSFORM"}
    </ButtonGradient>
  );
};

const bigNumberToString = (big) => {
  const number = new Number(big).valueOf() / 10 ** 18;
  let str;
  let parts = number.toString().split(".");
  if (parts.length > 1) {
    const decimals = parts[1].length;
    str = number.toFixed(decimals);
  } else {
    parts = number.toExponential().split("-");
    let decimals = +parts[1];
    if (decimals < 1) {
      decimals = 1;
    }
    str = number.toFixed(decimals);
  }

  return str;
};

const HomePage = () => {
  const navigate = useNavigate();
  const { active, account } = useWeb3React();
  const [nft, setNFT] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [showAlertInsufficient, setShowAlertInsufficient] =
    React.useState(false);
  const [isLoadingButton, setIsLoadingButton] = React.useState(false);
  const [isLoadingPage, setIsLoadingPage] = React.useState(true);
  const [evolveType, setEvolveType] = React.useState("left");
  const [imgHeight, setImgHeight] = React.useState();
  const [sideLeftImage, setSideLeftImage] = React.useState(undefined);
  const [sideRightImage, setSideRightImage] = React.useState(undefined);
  const [canLeftClaim, setCanLeftClaim] = React.useState(false);
  const [canRightClaim, setCanRightClaim] = React.useState(false);
  const [ownAssets, setOwnAssets] = React.useState([]);
  const [priceTransform, setPriceTransform] = React.useState(0);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  // console.log({ evolveType, L: nft.linkLeft, R: nft.linkRight });
  //

  useEffect(() => {
    if (!active) {
      navigate("/connect");
    }
  }, [active])

  const handleEvolveType = (e) => {
    setEvolveType(e.target.value);
  };

  const handleNFT = async (e) => {
    setIsLoadingPage(true);
    setNFT(e.target.value);
    await onLoadNFTTransform(e.target.value);
    setIsLoadingPage(false);
  };

  const getContractSigner = async () => {
    const address = process.env.REACT_APP_PAYMENT_ADDRESS;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(address, abi, provider);
    const contractSigner = await contract.connect(signer);
    return contractSigner;
  };

  const onSubmitTransform = async () => {
    setIsLoadingButton(true);
    const tokenId = nft.tokenId;
    const isLeft = evolveType === "left";

    const contractSinger = await getContractSigner();
    console.log({ evolveType, priceTransform, nft, contractSinger });

    if (contractSinger) {
      await contractSinger
        .purchase(tokenId, isLeft, {
          value: ethers.utils.parseEther(priceTransform),
        })
        .catch((err) => {
          switch (err.code) {
            case "INSUFFICIENT_FUNDS":
              setIsLoadingButton(false);
              setShowAlertInsufficient(true);
              break;
            default:
              break;
          }

          setIsLoadingButton(false);
          console.log({ err });
        });

      await axios
        .post(process.env.REACT_APP_API_TRANSFOM, {
          tokenId,
        })
        .then((response) => console.log({ response }))
        .catch((e) => console.log(e));
      setShowAlert(true);
    }
    setIsLoadingButton(false);
  };

  const onLoadNFTTransform = async (nftRoot) => {
    const tokenId = nftRoot.tokenId;
    const schemaCode = process.env.REACT_APP_CURRENT_ENV === "production" ? "buakaw1.buakaw1xamazingthailand" : "pear.testing-v5-21";
    const apiClient = await sixConnector.connectAPIClient();
    const response = await apiClient.nftmngrModule.queryNftData(
      schemaCode,
      tokenId
    );

    const contractSinger = await getContractSigner();
    if (response) {
      const nftData = _.get(response, "data.nftData");
      const filterName = [
        "side_left",
        "side_l",
        "side_right",
        "side_r",
        "serum_applied",
      ];

      const nftObj = {
        tokenId,
        canLeftClaim: false,
        canRightClaim: false,
        isLow: false,
      };
      nftObj.title =
        nftRoot.title ||
        `Buakaw1 x Amazing Thailand #${tokenId.padStart(4, "0")}`;
      nftObj.origin_image = nftData.origin_image;
      nftObj.onchain_image = nftData.onchain_image;
      nftData.onchain_attributes
        .filter((n) => filterName.includes(n.name))
        .forEach(({ name, string_attribute_value }) => {
          if (name === "side_left" || name === "side_l")
            nftObj.sideLeft = string_attribute_value.value;
          else if (name === "side_right" || name === "side_r")
            nftObj.sideRight = string_attribute_value.value;
          else nftObj[name] = string_attribute_value.value;
        });

      nftObj.linkLeft = `https://bk1nft.sixnetwork.io/ipfs/${nftObj.sideLeft}/${tokenId}.png`;
      nftObj.linkRight = `https://bk1nft.sixnetwork.io/ipfs/${nftObj.sideRight}/${tokenId}.png`;

      const claimedBig = await contractSinger.claimed(tokenId);
      nftObj.claimed = Number(bigNumberToString(claimedBig)) > 0;

      if (!nftObj.claimed) {
        if (Number(tokenId) > 43) {
          let leftClaimed = true;
          let rightClaimed = true;
          const bigLeftGroupID = await contractSinger.tokenIdToLGroup(tokenId);
          const groupId_L = bigNumberToString(bigLeftGroupID);
          if (groupId_L === "0" || groupId_L === 0) leftClaimed = false;
          else leftClaimed = await contractSinger.groupClaimed(bigLeftGroupID);

          const bigRightGroupID = await contractSinger.tokenIdToRGroup(tokenId);
          const groupId_R = bigNumberToString(bigRightGroupID);
          if (groupId_R === "0" || groupId_R === 0) rightClaimed = false;
          else
            rightClaimed = await contractSinger.groupClaimed(bigRightGroupID);

          nftObj.canLeftClaim = !leftClaimed;
          nftObj.canRightClaim = !rightClaimed;
        } else nftObj.isLow = true;
        {
        }
      } else if (nftObj.serum_applied === "None" && nftObj.claimed) {
        await axios
          .post(process.env.REACT_APP_API_TRANSFOM, {
            tokenId,
          })
          .then((response) => console.log({ response }))
          .catch((e) => console.log(e));
        setShowAlert(true);
      }

      if (nftObj.canLeftClaim && !nftObj.canRightClaim) {
        setEvolveType("left");
      } else if (!nftObj.canLeftClaim && nftObj.canRightClaim) {
        setEvolveType("right");
      } else if (!nftObj.canLeftClaim && !nftObj.canRightClaim) {
        setEvolveType(null);
      } else {
        setEvolveType("left");
      }

      console.log({
        response,
        nftObj,
      });

      return nftObj;
    }
  };

  const getDescription = () => {
    if (nft.isLow)
      return <FormattedMessage id="Your NFT does not meet the conditions" />;
    else if (nft.canLeftClaim && nft.canRightClaim)
      return <FormattedMessage id="You can" />;
    else if (nft.canLeftClaim && !nft.canRightClaim)
      return <FormattedMessage id="You may only use the potion for the left" />;
    else if (!nft.canLeftClaim && nft.canRightClaim)
      return (
        <FormattedMessage id="You may only use the potion for the right" />
      );
    else if (!nft.canLeftClaim && !nft.canRightClaim)
      return <FormattedMessage id="Your NFT has transformation" />;
  };

  useEffect(() => {
    async function loadData() {
      setIsLoadingPage(true);
      setIsLoadingButton(true);
      const address = process.env.REACT_APP_PAYMENT_ADDRESS;
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = await provider.getSigner();
      const walletAddress = await signer.getAddress();
      const contract = new ethers.Contract(address, abi, provider);
      const contractWithSigner = await contract.connect(signer);

      // Load price
      const priceBignumber = await contractWithSigner.price();
      const priceString = bigNumberToString(priceBignumber);
      setPriceTransform(priceString);

      // Load Nft
      const ownedNfts = (
        await alchemy.nft.getNftsForOwner(walletAddress, {
          tokenUriTimeoutInMs: 10000,
        })
      ).ownedNfts.filter(
        (x) =>
          x.contract?.address ===
          (process.env.REACT_APP_EVO_ADDRESS || "").toLowerCase()
      );

      console.log({ ownedNfts });
      const nfts = await Promise.all(
        ownedNfts.map(async (nft) => await onLoadNFTTransform(nft))
      );

      console.log({ nfts });
      setOwnAssets(nfts);
      if (nfts.length > 0) {
        setNFT(nfts[0]);
      }

      setIsLoadingButton(false);
      setIsLoadingPage(false);
    }

    loadData();
  }, [account]);

  return (
    <>
      <Title />

      <Box
        display="flex"
        justifyContent={{ xs: "center", md: "space-between" }}
        flexWrap="wrap"
      >
        <Box width={{ xs: "100%", md: "28%" }} maxWidth="400px">
          <Typography variant="h6" textTransform="uppercase" mb={2}>
            <FormattedMessage id="Choose your fighter" />
          </Typography>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <Select size="small" value={nft} onChange={handleNFT}>
              {(ownAssets || []).map((nft, idx) => (
                <MenuItem key={`nft-${idx}`} value={nft}>
                  {nft.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <MyNFT nft={nft} setImgHeight={setImgHeight} isLoading={true} />
        </Box>

        <Box
          width={{ xs: "100%", md: "35%" }}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="center"
          px={{ xs: 0, md: 5 }}
          py={{ xs: 3, md: 0 }}
          flexGrow={1}
          maxWidth="400px"
        >
          <img src={evolveType === "left" ? left : right} alt="" />

          <Typography variant="h6" align="center" my={1}>
            {priceTransform} ETH
          </Typography>

          <RadioGroup
            row
            defaultChecked
            value={evolveType}
            onChange={handleEvolveType}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <FormControlLabel
              disabled={!nft.canLeftClaim}
              value="left"
              control={<Radio />}
              label={<FormattedMessage id="Left" />}
              sx={{ mr: 5 }}
            />
            <FormControlLabel
              disabled={!nft.canRightClaim}
              value="right"
              control={<Radio />}
              label={<FormattedMessage id="Right" />}
            />
          </RadioGroup>

          {md && (
            <TransformButton
              disabled={
                isLoadingButton || (!nft.canLeftClaim && !nft.canRightClaim)
              }
              isLoading={isLoadingButton}
              onClick={onSubmitTransform}
            />
          )}

          <Typography
            // variant="body2"
            fontWeight={300}
            sx={{ opacity: "0.7" }}
            whiteSpace="pre-line"
          >
            {getDescription()}
          </Typography>
        </Box>

        <Box
          width={{ xs: "100%", md: "37%" }}
          maxWidth="400px"
          display="flex"
          flexDirection="column"
          flexShrink={0}
          flexGrow={1}
          alignSelf="stretch"
        >
          <Typography variant="h6" textTransform="uppercase" mb={2}>
            <FormattedMessage id="Preview" />
          </Typography>

          <Preview
            nft={nft}
            evolveType={evolveType}
            height={imgHeight}
            isLoading={true}
          />

          {!md && (
            <TransformButton
              disabled={
                isLoadingButton || (!nft.canLeftClaim && !nft.canRightClaim)
              }
              isLoading={isLoadingButton}
              sx={{ mt: 3 }}
              onClick={onSubmitTransform}
            />
          )}
        </Box>
      </Box>

      <AlertPopup
        open={showAlert}
        title={<FormattedMessage id="Congratulations" />}
        detail={<FormattedMessage id="You have evolved" />}
        img={evolveType === "left" ? nft.linkLeft : nft.linkRight}
        onClose={() => {
          setShowAlert(false);
        }}
        buttonLabel={<FormattedMessage id="Done" />}
      />

      <AlertPopup
        open={showAlertInsufficient}
        title={
          <FormattedMessage id="Sorry, Your balance is insufficient to transform this NFT." />
        }
        detail={
          <FormattedMessage id="Please deposit more ETH and try again." />
        }
        icon={
          <WarningAmberRoundedIcon
            color="primary"
            sx={{ m: "0 auto 16px auto", fontSize: "48px" }}
          />
        }
        buttonLabel={<FormattedMessage id="Ok" />}
        onClose={() => {
          setShowAlertInsufficient(false);
        }}
      />

      <LoadingPage isLoading={isLoadingPage} />
    </>
  );
};

export default HomePage;
