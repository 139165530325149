import { ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { createWeb3ReactRoot, Web3ReactProvider } from "web3-react-core";
import getLibrary from "./utils/getLibrary";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import TranslationStore from "./utility/context/Internationalization";

const Web3ProviderNetwork = createWeb3ReactRoot("NETWORK");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <TranslationStore>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </TranslationStore>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
