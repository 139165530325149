import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  typography: {
    fontFamily: '"Noto Sans","Noto Sans Thai", sans-serif',
    h3: {
      fontWeight: "bold",
      textShadow: "0px 4px 4px #C5BDBD",
    },
    h6: {
      fontWeight: "bold",
    },
  },
  palette: {
    primary: {
      main: "#D7A137",
    },
    text: {
      primary: "#fff",
      secondary: "#9C9C9C",
      disabled: "rgba(255,255,255,0.35)",
    },
    action: {
      disabled: "rgba(255,255,255,0.35)",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          maxWidth: "200px",
          color: "white",
        },
        contained: {
          "&.MuiButton-fullWidth": {
            // background: "#FFFFFF",
            borderRadius: "24px",
            color: "initial",
            padding: "4px 16px",
          },
        },
        text: {
          "&:hover": {
            color: "#E09F00",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "white",
          "& svg": { color: "white" },

          ":hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#D7A137",
            },
          },
        },
        notchedOutline: {
          borderWidth: "1px !important",
          border: "1px solid rgba(85, 85, 85, 0.85)",
          boxShadow: "0px 0px 4px rgba(255, 255, 255, 0.25)",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          filter: "drop-shadow(0px 0px 4px #FFFFFF)",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          background: "rgba(255, 255, 255, 0.1)",
          backdropFilter: "blur(5px)",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "initial",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Noto Sans","Noto Sans Thai", sans-serif',
          lineHeight: "1.5",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: "#e09f00",
          width: "300px",
          maxWidth: "100%",
          padding: "120px 3rem 5rem",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
