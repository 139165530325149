import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import ConnectWalletPage from "./pages/ConnectWalletPage";
import HomePage from "./pages/HomePage";
import "./stylesheets/global.scss";
import Header from "./components/Header";
import { Container } from "@mui/material";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <Header />
      <Container
        maxWidth="lg"
        sx={{
          py: { xs: 5, md: 8 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="flex-grow">
          <Routes>
            <Route index path="/" element={<HomePage />} />
            <Route path="/connect" element={<ConnectWalletPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
