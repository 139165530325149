import { Button, styled } from "@mui/material";

const ButtonGradient = styled(Button)`
  background: linear-gradient(269.27deg, #840022 20.91%, #103aa5 147.06%);
  box-shadow: 0px 0px 5px #9a9697;
  max-width: initial;
  border-radius: 0;
  color: white !important;
  transition: 0.2s;
  display: flex !important;
  align-items: center;

  &:disabled {
    opacity: 0.5;
    box-shadow: none !important;
  }
`;

export default ButtonGradient;
