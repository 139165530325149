import { Box, Dialog, Typography } from "@mui/material";
import React from "react";
import ButtonGradient from "./ButtonGradient";
import RatioBox from "./RatioBox";

const AlertPopup = ({
  open,
  onClose,
  title,
  detail,
  img,
  icon,
  buttonLabel,
}) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          background: "#000000",
          flexDirection: { xs: "column", md: "row" },
          m: 0,
        },
      }}
    >
      {img && (
        <div>
          <RatioBox width="280px !important">
            <img src={img} alt="" className="d-block" />
          </RatioBox>
        </div>
      )}

      <Box
        p={3}
        display="flex"
        flexDirection="column"
        minWidth={{ md: "280px" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          p={2}
        >
          {icon}
          <Typography
            variant="h5"
            fontWeight={300}
            align="center"
            mb={1}
            whiteSpace="pre-line"
          >
            {title}
          </Typography>
          <Typography
            fontWeight={300}
            align="center"
            whiteSpace="pre-line"
            sx={{ opacity: "0.7" }}
            mb={2}
          >
            {detail}
          </Typography>
        </Box>

        {buttonLabel && (
          <ButtonGradient fullWidth sx={{ mt: "auto" }} onClick={onClose}>
            {buttonLabel}
          </ButtonGradient>
        )}
      </Box>
    </Dialog>
  );
};

export default AlertPopup;
