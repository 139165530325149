import { Box, styled, Typography } from "@mui/material";
import React from "react";
import Socials from "./Socials";

const CustomBar = styled(Box)`
  background: #0d0d0d;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  color: ${({ theme }) => theme.palette.white};
  display: flex;
  align-items: center;
`;

const Footer = () => {
  return (
    <CustomBar
      component="footer"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        py: "0.5rem",
        px: { xs: "1rem", md: "2.5rem" },
        alignItems: "center",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Typography className="py-2">
        © 2022 Baukaw1 All rights reserved.
      </Typography>

      <Socials className="py-2" />
    </CustomBar>
  );
};

export default Footer;
