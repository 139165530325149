import { Box, Card, LinearProgress, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import sample from "../images/sample.png";
import RatioBox from "./RatioBox";

const MyNFT = ({ setImgHeight, nft }) => {
  const [prevId, setPrevId] = React.useState(null);
  const [londing, setLoading] = React.useState(true);
  const boxRef = useRef();

  useEffect(() => {
    if (prevId != nft.tokenId) {
      setPrevId(nft.tokenId);
      setLoading(true);
    }
  }, [nft]);

  useEffect(() => {
    if (boxRef) {
      setImgHeight(boxRef.current.offsetHeight);
    }
  }, []);

  return (
    <Card>
      <RatioBox ref={boxRef} className={londing ? "loading" : ""}>
        {londing && (
          <div>
            <LinearProgress />
          </div>
        )}
        <img
          src={nft.onchain_image ? nft.onchain_image : nft.origin_image}
          alt=""
          width="100%"
          height="auto"
          onLoad={(e) => {
            setLoading(false);
            setImgHeight(e.target.parentElement.offsetHeight);
          }}
        />
      </RatioBox>
      <Box sx={{ background: "#0D0D0D" }} p={2}>
        <Typography fontWeight={700} noWrap>
          {_.get(nft, "title", "UNKNOWN").split("#")[0]}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
          fontWeight={700}
          noWrap
        >
          #{_.get(nft, "tokenId", "UNKNOWN").padStart(4, "0")}
        </Typography>
      </Box>
    </Card>
  );
};

export default MyNFT;
