import { CloseRounded, Logout } from "@mui/icons-material";
import {
  Button,
  Drawer,
  IconButton,
  MenuItem,
  MenuList,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { UnsupportedChainIdError, useWeb3React } from "web3-react-core";
import { injected } from "../connectors";
import menus from "../menus";
import Socials from "./Socials";

const RightDrawer = ({ open, onClose }) => {
  const { active, account, activate, deactivate } = useWeb3React();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const handleConnectWallet = () => {
    activate(injected, undefined, true)
      .then(async () => {
        // eslint-disable-next-line
        const walletAddress = await injected.getAccount();
        localStorage.setItem("isWalletConnected", "connected");
      })
      .catch((error) => {
        if (error instanceof UnsupportedChainIdError) {
          activate(injected);
        } else {
          console.error(error);
        }
      });
  };

  const logout = () => {
    localStorage.setItem("isWalletConnected", "disconnect");
    deactivate();
  };

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem("isWalletConnected") === "connected") {
        try {
          await handleConnectWallet();
          localStorage.setItem("isWalletConnected", "connected");
        } catch (ex) {
          localStorage.setItem("isWalletConnected", "disconnect");
        }
      }
    };
    connectWalletOnPageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer open={open} onClose={onClose} variant="temporary" anchor="right">
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", top: "16px", right: "16px" }}
      >
        <CloseRounded sx={{ color: "white" }} />
      </IconButton>
      <MenuList>
        {menus.map((m, index) => (
          <MenuItem
            key={index}
            className="py-3 px-0"
            component="a"
            href={m.path}
            target="_blank"
            sx={{ fontSize: "20px", fontWeight: "bold", color: "white" }}
          >
            {m.name}
          </MenuItem>
        ))}
      </MenuList>
      {!active ? (
        <Button
          size="large"
          variant="contained"
          fullWidth
          onClick={handleConnectWallet}
          className={mdUp ? "" : "mx-auto"}
          sx={{ alignSelf: "center" }}
        >
          <FormattedMessage id="Connect Wallet" />
        </Button>
      ) : (
        <div className="d-flex align-center ml-8">
          <Button
            size="medium"
            variant="contained"
            fullWidth
            className={mdUp ? "" : "mx-auto"}
          >
            {`${account.substring(0, 6)}...${account.substring(
              account.length - 4
            )}`}
          </Button>
          <IconButton onClick={logout}>
            <Logout sx={{ color: "white" }} />
          </IconButton>
        </div>
      )}
      <Socials
        className="flex-wrap mt-2"
        btnClassName="px-0 mr-3 mt-3"
        height={20}
      />
    </Drawer>
  );
};

export default RightDrawer;
