import { Box, styled } from "@mui/material";

const RatioBox = styled(Box)`
  position: relative;
  width: 100%;
  padding-top: 100%;
  background: rgba(0, 0, 0, 0.9);
  overflow: hidden;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }

  > div:nth-of-type(01) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.3);
    transition: 0.3s;
    opacity: 0;

    .MuiLinearProgress-root {
      margin-top: auto;
      background: rgb(215 161 55 / 25%);
    }
  }

  &.loading {
    > div:nth-of-type(01) {
      opacity: 1;
    }
  }
`;

export default RatioBox;
